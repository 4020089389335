<template>
    <multiselect
        v-model="selectedOption"
        label="text"
        track-by="value"
        :options="options"
        :close-on-select="closeOnSelect"
        :clear-on-select="false"
        :preserve-search="true"
        :multiple="multiple"
        :show-no-results="showNoResults"
        :placeholder="placeholder"
        :custom-label="nameWithoutDiacritics"
        :select-label="$t('form.fields.text.select')"
        :deselect-label="$t('form.fields.text.deselect')"
    >
        <span slot="noResult">
            {{ $t('form.fields.text.noResult') }}
        </span>
        <span slot="noOptions">{{ $t('form.fields.text.noOptions') }}</span>
        <template
            slot="option"
            slot-scope="{ option }"
        >
            <span v-dompurify-html="nameWithoutDiacritics(option)" />
        </template>
        <template
            slot="singleLabel"
            slot-scope="{ option }"
        >
            <span v-dompurify-html="nameWithoutDiacritics(option)" />
        </template>
        <template
            slot="optionLabel"
            slot-scope="{ option }"
        >
            <span v-dompurify-html="nameWithoutDiacritics(option)" />
        </template>
    </multiselect>
</template>

<script>
    import Multiselect from 'vue-multiselect';
    export default {
        name: 'MultiSelectField',
        components: {
            Multiselect,
        },
        props: {
            options: {
                type: Array,
                default() {
                    return [];
                },
            },
            closeOnSelect: {
                type: Boolean,
                default: true,
            },
            showNoResults: {
                type: Boolean,
                default: false,
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            placeholder: {
                type: String,
                default: '',
            },
            type: {
                type: String,
                default: '',
            },
            selected: {
                type: [Object, Array],
                default: null,
            },
        },

        data() {
            return {
                selectedOption: null,
            };
        },
        methods: {
            nameWithoutDiacritics({ text, value }) {
                if(this.type != 'products'){
                    return `${text}<span class="hide">[${value}]</span>`;
                }
                return text;
            },
        },
        watch: {
            selectedOption: {
                handler() {
                    this.$emit('updateSelectedOption', {
                        type: this.type,
                        value: this.selectedOption,
                    });
                },
                deep: true,
            },
            selected: {
                handler() {
                    this.selectedOption = this.selected;
                },
                deep: true,
            },
        },
    };
</script>
<style lang="scss">
@import 'src/_scss/components/multiselect.scss';
</style>
